<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Carica un file</span>
        </v-card-title>
        <v-card-subtitle>
          <span class="text-caption">Aggiungi un file direttamente all'interno dello spazio condiviso o di una cartella.</span>
        </v-card-subtitle>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <vue-dropzone
                  id="dropzone"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-complete="getUploadResponse"
                  @vdropzone-error="manageDropzoneError"
                ></vue-dropzone>
              </v-col>
              <v-card-actions
                cols="12"
                class="w-full d-flex justify-space-between"
              >
                <div>
                  <v-btn
                    v-if="fileInError"
                    color="warning"
                    @click="cleanQueueFiles"
                  >
                    Reset Files
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    class="ma-2"
                    :disabled="loading"
                    @click="closeModal"
                  >
                    Chiudi
                  </v-btn>
                  <v-btn
                    :color="btnColor"
                    :dark="btnDark"
                    class="ma-2"
                    :loading="loading"
                    @click.prevent="create"
                  >
                    Carica
                  </v-btn>
                </div>
              </v-card-actions>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'GroupFileCreate',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    folderId: {
      type: Number,
      default: 0,
    },
    uploadUrl: {
      type: String,
      default: 'url',
      required: true,
    },
  },
  data() {
    return {
      initialForm: {},
      form: {},
      loading: false,
      fileInError: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    dropzoneOptions() {
      return {
        dictDefaultMessage: 'Trascina i file oppure clicca per caricare',
        url: 'url',
        thumbnailWidth: 150,
        maxFilesize: 200,
        timeout: 180000,
        autoProcessQueue: false,
        headers: { Authorization: `Bearer ${this.$store.state.auth.token}` },
        paramName: 'files',
      }
    },
  },
  watch: {
    folderRef(val) {
      this.form.parent_id = val
      this.initialForm.parent_id = val
    },
    isShow() {
      if (this.isShow === true) {
        this.setDropzoneUrl()
        if (this.$refs.myVueDropzone) {
          this.$refs.myVueDropzone.dropzone.options.url = this.uploadUrl
        }
      }
    },
  },
  mounted() {
    this.setInitialValues()
    if (this.folderRef) {
      this.form.parent_id = this.folderRef
      this.form.folder_id = this.folderId
      this.initialForm.parent_id = this.folderRef
    }
  },
  methods: {
    setInitialValues() {
      this.form = {
        ...this.initialForm,
      }
    },
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          this.$refs.myVueDropzone.processQueue()
        } catch (error) {
          console.log(error)
        }
      }
    },
    async closeModal() {
      await this.$refs.form.reset()
      await this.$emit('close')
      await this.setInitialValues()
      this.$refs.myVueDropzone.removeAllFiles()
      this.loading = false
    },
    setDropzoneUrl() {
      this.dropzoneOptions.url = this.uploadUrl
    },
    async getUploadResponse(resp) {
      const response = JSON.parse(resp.xhr.response)
      if (response.status === 'success') {
        await this.$store.dispatch('set_alert', { msg: 'File caricato con successo', type: 'success' })
        await this.$emit('reload-data')
        this.loading = false
        await this.closeModal()
      } else {
        await this.$store.dispatch('set_alert', { msg: response.message, type: 'error' })
        this.loading = false
        await this.closeModal()
      }
    },
    manageDropzoneError(file, errorMessage, xhr) {
      this.loading = false
      this.fileInError = true;
    },
    cleanQueueFiles() {
      this.$refs.myVueDropzone.removeAllFiles()
      this.fileInError = false
    },
  },
}
</script>

<style scoped>

</style>
