<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="px-0"
      >
        <group-documents-header
          show-reload-resource
          :loading-reload-resource="loading.reloadResource"
          back-button-text="Indietro"
          :back-button-route="calculateBackRoute"
          @clickCreateDocument="isShow.create = true"
          @clickCreateFolder="isShow.folder_create = true"
          @clickCreateFile="isShow.file_create = true"
          @clickReloadResource="getData"
        />

        <div
          class="hidden-xs-only"
        >
          <group-card
            section-name="Spazio condiviso"
          />
          <!-- BREADCRUMB only on desktop -->
          <v-row
            class="mt-0 hidden-xs-only"
          >
            <v-col cols="12">
              <v-breadcrumbs
                class="pl-0"
                large
                :items="calculateBreadcrumb"
                divider=">"
              >
                <template
                  #item="{ item }"
                >
                  <v-breadcrumbs-item
                    :disabled="item.disabled"
                    :to="item.to"
                    @dragover="onDragEnter($event, item.id)"
                    @dragenter="onDragEnter($event, item.id)"
                    @dragleave="onDragLeave($event, item.id)"
                    @drop="onDrop($event, item.id)"
                  >
                    <v-chip
                      :color="breadCrumbColors[item.id]"
                    >
                      {{ item.text }}
                    </v-chip>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </v-col>
          </v-row>

          <!-- GROUP FOLDERS -->
          <v-row class="mt-0">
            <v-col
              v-for="(folder,idx) in currentLevelFolders"
              :key="'F'+idx"
              cols="12"
              sm="12"
              md="3"
            >
              <folder-card
                is-plugin-version
                folder-route="pluginGroupFolderView"
                :folder-obj="folder"
                :folder-permissions="folder.group_folder_permissions"
                :go-to-folder-target-id="folder.group_id"
                :api-routes="apiRoutes"
                :api-target-id="folder.group_id"
                @reloadData="getData"
                @droppedItem="setIsDropped"
                @droppedFile="setIsDroppedFile"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="(doc,idx) in currentLevelDocuments"
              :key="idx"
              cols="12"
              sm="12"
              md="4"
            >
              <group-documents-card
                is-draggable
                document-route="pluginGroupDocumentFiles"
                is-plugin-version
                :document-obj="doc"
                :is-dropped="isDropped[doc.id]"
                @reload-data="getData"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="(file, idxF) in currentLevelFiles"
              :key="idxF"
              cols="12"
              sm="12"
              md="3"
            >
              <file-card
                no-thumb
                is-plugin-version
                can-handle-file
                :is-dropped="isDroppedFile[file.id]"
                :is-draggable="true"
                :file-type="'group'"
                :procedure-id="1"
                :file-obj="file"
                :download-url="downloadUrl(file.id)"
                @reloadData="getData"
              />
            </v-col>
          </v-row>
        </div>
        <div
          class="hidden-sm-and-up"
        >
          <mobile-group-entities-list
            folder-route="pluginGroupFolderView"
            :list-title="currentFolder.name"
            :folders="currentLevelFolders"
            :files="currentLevelFiles"
            :is-page-fully-loaded="isPageFullyLoaded"
          />
        </div>
        <!-- MODALS -->
        <create-group-folder
          v-if="isOwner && isPageFullyLoaded"
          is-plugin-version
          :is-show="isShow.folder_create"
          :group-id="loggedUserGroup.id"
          :folder-ref="currentFolder.unique_ref"
          @close="isShow.folder_create=false"
          @reload-data="getData"
        />
        <create-group-document
          v-if="isOwner && isPageFullyLoaded"
          no-auto-import
          no-priority
          :is-show="isShow.create"
          :group-id="loggedUserGroup.id"
          :folder-id="currentFolder.id"
          @close="isShow.create=false"
          @reload-data="getData"
        />
        <create-group-file
          v-if="isOwner"
          no-auto-import
          no-priority
          :is-show="isShow.file_create"
          :upload-url="uploadUrl"
          @close="isShow.file_create=false"
          @reload-data="getData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import GroupDocumentsHeader from '@/components/section-headers/group-documents-header.vue'
import GroupCard from '@/components/cards/group-card.vue'
import GroupDocumentsCard from '@/components/cards/group-documents-card.vue'
import CreateGroupDocument from '@/components/modals/create-group-document.vue'
import CreateGroupFolder from '@/components/modals/create-group-folder.vue'
import FolderCard from '@/components/cards/folder-card.vue'
import FileCard from '@/components/cards/file-card.vue'
import CreateGroupFile from '@/components/modals/create-group-file.vue'
import MobileGroupEntitiesList from "@/components/lists/mobile-group-entities-list.vue";

export default {
  name: 'PluginGroupFolder',
  components: {
    MobileGroupEntitiesList,
    CreateGroupFile,
    FileCard,
    FolderCard,
    CreateGroupDocument,
    GroupDocumentsCard,
    GroupCard,
    GroupDocumentsHeader,
    CreateGroupFolder,
  },
  data() {
    return {
      isDropped: {},
      isDroppedFile: {},
      isPageFullyLoaded: false,
      isShow: {
        create: false,
        edit: false,
        destroy: false,
        folder_create: false,
        file_create: false,
      },
      upload_url: '',
      currentFolder: {},
      pluginSettings: {
        resourceFound: null,
      },
      loading: {
        reloadResource: false,
      },
      apiRoutes: {
        editApiMethod: 'editPluginFolderGroup',
        deleteApiMethod: 'destroyPluginFolderGroup',
        editDocumentApiMethod: 'editGroupDocument',
        permGetApiMethod: 'getGroupFolderPermissions',
        permSetApiMethod: 'setGroupFolderPermission',
        permEditApiMethod: 'updateGroupFolderPermission',
        permDeleteApiMethod: 'deleteGroupFolderPermission',
        editFileApiMethod: 'editGroupFile',
      },
      breadCrumbColors: [],
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'loggedUserGroup', 'partnerSettings', 'groupDocumentsList', 'groupFolders', 'groupFilesList']),
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    calculateBackRoute() {
      if (this.groupFolders) {
        // if currentFolder is defined, check if it has a parent_id and is not 0
        if (this.currentFolder && this.currentFolder.parent_id && this.currentFolder.parent_id !== 0) {
          // find the parent folder
          const parentFolder = this.groupFolders.find(item => item.id === this.currentFolder.parent_id)

          // if it has a parent_id, return the route to the parent folder
          return `/plugin-group-folder/${this.loggedUserGroup.id}/folder/${parentFolder.unique_ref}`
        }

        return '/plugin-group-documents'
      }

      return '/plugin-group-documents'
    },
    currentLevelFolders() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.groupFolders.filter(item => item.parent_id === this.currentFolder.id)
      }

      return []
    },
    currentLevelDocuments() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.groupDocumentsList.filter(item => item.folder_id === this.currentFolder.id)
      }

      return []
    },
    currentLevelFiles() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.groupFilesList.filter(item => item.group_folder_id === this.currentFolder.id)
      }

      return []
    },
    calculateBreadcrumb() {
      // create an array of objects containing the nodes: text, disabled, href
      // the first node has always the name "Spazio condiviso" and the href to the group documents page
      const breadcrumb = [
        {
          text: 'Spazio condiviso',
          disabled: false,
          to: '/plugin-group-documents',
          id: 0,
        },
      ]

      const tempFolders = []
      const findParentFolder = folder => {
        const parentFolder = this.groupFolders.find(item => item.id === folder.parent_id)
        if (parentFolder) {
          // if it has a parent_id, return the route to the parent folder
          tempFolders.push({
            text: parentFolder.name,
            disabled: false,
            to: `/plugin-group-folder/${this.loggedUserGroup.id}/folder/${parentFolder.unique_ref}`,
            id: parentFolder.id,
          })
          findParentFolder(parentFolder)
        }
      }

      findParentFolder(this.currentFolder)

      // reverse the array to have the correct order
      tempFolders.reverse()
      breadcrumb.push(...tempFolders)

      // the last node has always the name of the current folder and is disabled because is the current page
      breadcrumb.push({
        text: this.currentFolder.name,
        disabled: true,
      })

      return breadcrumb
    },
    uploadUrl() {
      return `${this.$apiUrl}ext/group-files/${this.currentFolder.id}/${this.loggedUserGroup.id}`
    },
  },
  watch: {
    '$route.params.fid': function () {
      this.setCurrentFolder()
    },
  },
  async created() {
    this.getData()
      .then(() => this.setCurrentFolder())
      .then(() => this.setPageIsFullyLoaded(true))
  },
  async mounted() {
    if (this.isPageFullyLoaded) {
      try {
        await this.getData()
        await this.setResourceFound(true)
      } catch (error) {
        if (error.status === 404) {
          await this.setResourceFound(false)
        } else {
          console.log(error)
        }
      }
    }
  },
  methods: {
    async getData() {
      this.loading.reloadResource = true
      await this.$store.dispatch('getGroup', this.loggedUserGroup.id)
      await this.$store.dispatch('getGroupDocuments')
      await this.$store.dispatch('getGroupFiles')
      this.isDropped = {}
      this.isDroppedFile = {}
      this.loading.reloadResource = false
    },
    async setResourceFound(status) {
      this.pluginSettings.resourceFound = status
    },
    async setPageIsFullyLoaded(status) {
      this.isPageFullyLoaded = status
    },
    async setCurrentFolder() {
      if (this.groupFolders) {
        this.currentFolder = await this.groupFolders.find(item => item.unique_ref === this.$route.params.fid)
      }
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
    setIsDroppedFile(fileId) {
      this.$set(this.isDroppedFile, fileId, true)
    },
    downloadUrl(fileId) {
      return `${this.$apiUrl}ext/group-files/${this.loggedUserGroup.id}/files/${fileId}`
    },
    onDragEnter(e, id) {
      e.preventDefault()
      this.$set(this.breadCrumbColors, id, this.btnColor)
    },
    onDragLeave(e, id) {
      e.preventDefault()
      this.$set(this.breadCrumbColors, id, '')
    },
    async onDrop(e, id) {
      const dropEntity = e.dataTransfer.getData('dropEntity')
      const dropType = e.dataTransfer.getData('dropType')
      const documentId = e.dataTransfer.getData('documentId')
      const procedureId = e.dataTransfer.getData('procedureId')
      const groupId = e.dataTransfer.getData('groupId')
      const fileId = e.dataTransfer.getData('fileId')

      // Exception for direct files
      if (dropEntity === 'file') {
        if (dropType === 'group') {
          // If it's a group file
          try {
            // Emit a signal to the parent component to tell it that the file has been dropped
            this.setIsDroppedFile(fileId)

            const dataToSend = {
              folder_id: id,
            }

            await this.$api[this.apiRoutes.editFileApiMethod](fileId, dataToSend)

            await this.getData()

            this.$set(this.breadCrumbColors, id, '')
            await this.$store.dispatch('set_alert', { msg: 'File spostato con successo', type: 'success' })
          } catch (ex) {
            this.loading = false
            await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
          }
        }
      } else {
        let entityId = null
        if (procedureId !== '' && procedureId !== undefined) {
          entityId = procedureId
        } else if (groupId !== '' && groupId !== undefined) {
          entityId = groupId
        }

        try {
          this.$emit('droppedItem', documentId)
          const dataToSend = {
            folder_id: this.folderObj.id,
          }

          await this.$api[this.apiRoutes.editDocumentApiMethod](entityId, documentId, dataToSend)

          this.$emit('reloadData')
          this.$el.classList.remove('selectedFolder')
          await this.$store.dispatch('set_alert', { msg: 'Documento Spostato con successo', type: 'success' })
        } catch (ex) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 90%;
}
.v-breadcrumbs >>> a {
  color: rgb(143, 137, 152) !important;
}

</style>
