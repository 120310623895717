<template>
  <div>
    <v-skeleton-loader
      v-if="!isPageFullyLoaded"
      class="mt-4"
      :loading="loading"
      type="article"
    />
    <v-card
      v-else
    >
      <v-toolbar
        :color="btnColor"
        dense
        dark
        style="color: white !important;"
      >
        <v-toolbar-title>
          {{ listTitle }}
        </v-toolbar-title>
      </v-toolbar>

      <v-list
        subheader
        two-line
      >
        <v-subheader
          v-if="folders.length > 0"
        >
          Cartelle
        </v-subheader>
        <v-list-item
          v-for="(folder,idx) in folders"
          :key="'MF'+idx"
          @click="goToFolder(folder.id, folder.unique_ref)"
        >
          <v-icon
            class="mr-2"
            dark
            color="grey"
          >
            mdi-folder
          </v-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 0.9rem"
              v-text="folder.name"
            />

            <v-list-item-subtitle
              style="font-size: 0.7rem"
              v-text="''"
            />
          </v-list-item-content>
        </v-list-item>
        <v-subheader
          v-if="files.length > 0"
        >
          Files
        </v-subheader>
        <v-list-item
          v-for="(file,idx) in files"
          :key="'MFI'+idx"
        >
          <v-icon
            class="mr-2"
            dark
            :color="getEntityColorByExtension(file.extension)"
          >
            {{ getMaterialIconByExtension(file.extension) }}
          </v-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 0.9rem"
              v-text="file.original_name"
            />

            <v-list-item-subtitle
              style="font-size: 0.7rem"
              v-text="''"
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              :loading="loading.download[file.id]"
              @click="downloadWithAxios(file.original_name, file.id);"
            >
              <!-- download button -->
              <v-icon color="grey lighten-1">mdi-download</v-icon>

            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-if="thereAreNoElements()"
        >
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 0.9rem"
              v-text="'Nessun elemento presente'"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import FileMenu from "@/components/menus/file-menu.vue";
import DeleteFile from "@/components/modals/delete-file.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'MobileGroupEntitiesList',
  components: {DeleteFile, FileMenu},
  props: {
    listTitle: {
      type: String,
      required: true,
    },
    folderRoute: {
      type: String,
      required: true,
    },
    folders: {
      type: Array,
      default: () => []
    },
    documents: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      default: () => []
    },
    isPageFullyLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: {
        delete: false,
        upload: false,
      },
      loading: {
        download: {},
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUserGroup', 'token', "partnerSettings"]),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
  },
  methods: {
    thereAreNoElements() {
      return this.folders.length === 0 && this.files.length === 0
    },
    goToFolder(id, folderUniqueRef) {
      console.log('asd')
      this.$router.push({
        name: this.folderRoute,
        params: {
          id,
          fid: folderUniqueRef,
        },
      })
    },
    getEntityColorByExtension(extension) {
      switch (extension) {
        case 'pdf':
          return 'red'
        case 'doc':
        case 'docx':
          return 'blue'
        case 'xls':
        case 'xlsx':
          return 'green'
        case 'ppt':
        case 'pptx':
          return 'orange'
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'purple'
        case 'zip':
        case 'rar':
          return 'brown'
        case 'mov':
        case 'mp4':
        case 'avi':
          return 'pink'
        default:
          return 'grey'
      }
    },
    getMaterialIconByExtension(extension) {
      switch (extension) {
        case 'pdf':
          return 'mdi-file-pdf'
        case 'doc':
        case 'docx':
          return 'mdi-file-word'
        case 'xls':
        case 'xlsx':
          return 'mdi-file-excel'
        case 'ppt':
        case 'pptx':
          return 'mdi-file-powerpoint'
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'mdi-file-image'
        case 'zip':
        case 'rar':
          return 'mdi-file-zip'
        case 'mov':
        case 'mp4':
        case 'avi':
          return 'mdi-file-video'
        default:
          return 'mdi-file'
      }
    },
    downloadUrl(fileId) {
      return `${this.$apiUrl}ext/group-files/${this.loggedUserGroup.id}/files/${fileId}`
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(title, fid) {

      // set this download loading of fid to true
      this.$set(this.loading.download, fid, true)

      const url = this.downloadUrl(fid)
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          // set this download loading of fid to false
          this.$set(this.loading.download, fid, false)
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              this.$store.dispatch('set_alert', {
                msg: 'Non hai i permessi necessari per scaricare il file.',
                type: 'error',
              })
              break
            default:
              this.$store.dispatch('set_alert', {
                msg: 'Errore nel download, si prega di riprovare.',
                type: 'error',
              })
              break
          }
          this.loading.download = false
        })
    },
  }
}
</script>

<style
  scoped
>

</style>
