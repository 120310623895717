import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Carica un file")])]),_c(VCardSubtitle,[_c('span',{staticClass:"text-caption"},[_vm._v("Aggiungi un file direttamente all'interno dello spazio condiviso o di una cartella.")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.getUploadResponse,"vdropzone-error":_vm.manageDropzoneError}})],1),_c(VCardActions,{staticClass:"w-full d-flex justify-space-between",attrs:{"cols":"12"}},[_c('div',[(_vm.fileInError)?_c(VBtn,{attrs:{"color":"warning"},on:{"click":_vm.cleanQueueFiles}},[_vm._v(" Reset Files ")]):_vm._e()],1),_c('div',[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":_vm.btnColor,"dark":_vm.btnDark,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_vm._v(" Carica ")])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }